//import Navbar from "../components/Navbar";
//import Banner from "../components/Banner";
//import '../assets/styles/bootstrap.css';
//import '../assets/styles/style.css';
import '../assets/styles/landwind/input.css';
import '../assets/styles/landwind/output.css';
//import '../assets/styles/responsive.css';
//import '../assets/styles/font-awesome.min.css'
//import Features from "../components/Features";
//import HowItWorks from "../components/HowItWorks";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../components/homepage/Navbar';
import Hero from '../components/homepage/Hero';
import Body from '../components/homepage/Body';


const Homepage = () =>{
    return (
        <body>
            <div className="hero_area">
                <Navbar/>
                <Hero/>
                <Body/>
            </div>
        </body>
    )
}

export default Homepage;