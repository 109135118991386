import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loader1 from '../../assets/images/03-42-11-849_512.gif'
import axios from 'axios';
import defaultImage from '../../assets/images/userImage/defaultUserImage.png'

const CreateSwapForm = ({userData, onProfileUpdate, screenWidth}) => {
    const [swapSkill, setSwapSkill] = useState("")
    const [swapOfferCopy, setSwapOfferCopy] = useState("")
    const [error,setError] = useState({})

    const handleClick = () =>{
        alert("Backend Development in Progress...")
    }

    const validateField = (field, value) => {
        let error = '';
        if(value !== undefined){
            if (field === 'swapSkill') {
                if (value.trim() === '') {
                    error = 'Please complete your profile setup to Create a Swap';
                }
            }else if (field === 'swapOfferCopy') {
                if (value.trim() === '') {
                    error = 'Swap Offer Proposal is Required';
                }
            } 
        }
        setError((prevErrors) => ({ ...prevErrors, [field]: error }));
    };
    return(
        <>
            <form className='me-3 ms-3'>
                <div className='mt-2 mb-4'>
                    <p className='text-primary text-4xl font-bold text-justify'>Create Swap Offer</p>
                </div>
                <div className='row'>
                    <div className='col-md-12 mb-3'>
                        <div className='float-start font-bold'>
                            Select the Skill for this Swap offer
                        </div><br/>
                        <select 
                            name="swapSkill" 
                            className="rounded-lg px-6 py-3 w-full text-gray-500" 
                            value={swapSkill} 
                            onChange={(e)=>{setSwapSkill(e.target.value)}} 
                            onBlur={() => validateField('swapSkill', swapSkill)}
                            id="swapSkill"
                            placeholder="SwapSkill"
                        >
                            <option disabled="disabled">Select the Skill for this Swap offer</option>
                            {
                                userData.skills_possessed.map((skill, index)=>(
                                    <option key={index} value={skill}>{skill}</option>
                                ))
                            }
                        </select>
                        {error.swapSkill && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.swapSkill}</p>}
                    </div>
                    <div className='col-md-12 mb-2'>
                        <div className='float-start font-bold'>
                            Write Offer
                        </div><br/>
                        
                        <textarea 
                            className='rounded-lg px-6 py-3 w-full' 
                            value={swapOfferCopy} 
                            onChange={(e)=>{setSwapOfferCopy(e.target.value)}}
                            onBlur={() => validateField('swapOfferCopy', swapOfferCopy)}
                        ></textarea>
                        {error.swapOfferCopy && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.swapOfferCopy}</p>}
                    </div>

                    <div className='d-grid d-block mb-2 mt-1'>
                        <button type="button" onClick={handleClick} className='btn btn-primary btn-lg w-100'>Create Swap</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default CreateSwapForm;