import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';

const Navbar2 = () => {
    const [navLinkCollapsed, setNavLinkCollapsed] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const linkHidden = screenWidth < 780 && navLinkCollapsed ? 'd-none' : 'mt-1 mb-1';

    const handleCollapseButton = () => {
        setNavLinkCollapsed(!navLinkCollapsed);
    };

    return (
        <div>
            <header className="fixed-top">
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className="container-fluid">
                        <Link to="/" className="navbar-brand">
                            <img src={logo} alt="Swaptime Logo" style={{ width: '35%' }} />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={handleCollapseButton}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${navLinkCollapsed ? '' : 'show'}`} id="navbarNav">
                            <div className="ms-auto">
                                <Link to="/login" className={`btn btn-outline-light me-2 ${linkHidden}`}>
                                    Log in
                                </Link>
                                <Link to="/register" className={`btn btn-warning ${linkHidden}`}>
                                    Join the Community
                                </Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    );
}

export default Navbar2;
