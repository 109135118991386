import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import defaultImage from '../../assets/images/userImage/defaultUserImage.png';
import image1 from "../../assets/images/client-1.jpg";
import image2 from "../../assets/images/client-2.jpg";
import image3 from "../../assets/images/images-2.jpeg";
import image4 from "../../assets/images/AdobeStock_829005055.jpeg";

const ViewSwapOffers = ({ userData }) => {
  return (
    <>
      <div className='row ms-2'>
        <div className='mt-2 mb-3'>
          <p className='text-primary text-4xl font-bold text-justify'>View Swap Offers</p>
        </div>
        <ViewOfferComponent name="John Gele" image={image1} rate="4.8" skill="Video Editing" proposal="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias corrupti nemo molestiae tempore atque accusantium esse id quae! Molestiae repellendus et deleniti natus! Quae animi architecto quibusdam saepe suscipit necessitatibus." />
        <ViewOfferComponent name="Patricia Alice" image={image3} rate="4.9" skill="Video Editing" proposal="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias corrupti nemo molestiae tempore atque accusantium esse id quae! Molestiae repellendus et deleniti natus! Quae animi architecto quibusdam saepe suscipit necessitatibus." />
        <ViewOfferComponent name="Adex Yohan" image={image2} rate="4.7" skill="Graphics Design" proposal="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias corrupti nemo molestiae tempore atque accusantium esse id quae! Molestiae repellendus et deleniti natus! Quae animi architecto quibusdam saepe suscipit necessitatibus." />
        <ViewOfferComponent name="James" image={defaultImage} rate="4.5" skill="Graphics Design" proposal="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias corrupti nemo molestiae tempore atque accusantium esse id quae! Molestiae repellendus et deleniti natus! Quae animi architecto quibusdam saepe suscipit necessitatibus." />
      </div>
    </>
  );
};

export const ViewOfferComponent = ({ name, image, rate, skill, proposal }) => {
  const [modalId, setModalId] = useState("");

  useEffect(() => {
    setModalId(name.replace(/\s+/g, '-') + '-' + skill.replace(/\s+/g, '-'));
  }, [name, skill]);


  const handleClick = () => {
    alert("Backend Development in Progress...");
  };

  return (
    <div className="card m-2 col-md-3 bg-dark" style={{ width: '18rem' }} key={modalId}>
      <img src={image} className="card-img-top mt-2 mb-1" alt={name} />
      <div className="card-body bg-light rounded mb-2">
        <b className="card-title mb-3 fs-4">{name}</b><br />
        <span className="card-text mt-3">Rating: {rate}/5</span><br />
        <span className="card-text mb-4">Swap Skill: {skill}</span><br /><br />
        <button 
          data-bs-toggle="modal" data-bs-target={`#${modalId}`}
          className="btn btn-primary w-100"
        >
          See More
        </button>
      </div>
      <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={`modalLabel-${modalId}`} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className='fa fa-fw fa-close'></i></button>
            </div>
            <div className="modal-body">
              <center>
                <img src={image} alt="User Image" className="img-fluid mb-3 rounded" />
              </center>
              <p><b>Swap Skill:</b> {skill}</p>
              <p><b>Rating:</b> {rate}/5</p>
              <p>Proposal: {proposal}</p>
              <button onClick={handleClick} className='btn btn-success w-100 btn-md mt-2 mb-2'>Accept</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewSwapOffers;
