import React from 'react';
import { Link } from 'react-router-dom';
import hero_image from '../../assets/images/AdobeStock_508035363.jpeg';

const Hero = () => {
    return (
        <section className="d-flex align-items-center justify-content-center vh-100 text-center" style={{ backgroundImage: `url(${hero_image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className=' d-block d-md-none d-lg-none col-md-12'>
                <div className="container text-white bg-dark p-4 rounded mt-5 h-25" style={{width:"95%",opacity:"90%"}}>
                    <h3 className="display-4 fw-bold mb-4 fs-3">Trade skills you have for the skills you need</h3>
                    <p className="lead mb-4 fs-6">
                        Swaptime is a global online marketplace using a barter system, where entrepreneurs and small business owners can swap skills they have for skills they need.
                    </p>
                    <Link to="/register" className="btn btn-warning btn-lg">
                        Join the Community
                    </Link>
                </div>
            </div>
            <div className='d-none d-md-block d-lg-block col-md-6'>
                <div className="container text-white bg-dark p-4 rounded mt-5 h-25" style={{width:"90%",opacity:"90%"}}>
                    <h3 className="display-4 fw-bold mb-4 fs-2">Trade skills you have for the skills you need</h3>
                    <p className="lead mb-4 fs-5">
                        Swaptime is a global online marketplace using a barter system, where entrepreneurs and small business owners can swap skills they have for skills they need.
                    </p>
                    <Link to="/register" className="btn btn-warning btn-lg">
                        Join the Community
                    </Link>
                </div>
            </div>
            <div className=' d-none d-md-block d-lg-block col-md-6'>

            </div>
        </section>
    );
}

export default Hero;
