import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Homepage from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ThankYouPage from './pages/ThankYou';
import PrivateRoute from './components/auth/PrivateRoute';

function App() {
  useEffect(() => {
    document.title = "Swaptime: Connect with individuals who have skills you need";
  }, []);

  return (
    <Router>
      <body className='content' >
        <Routes>
          <Route index element={<Homepage />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/d2" element={<Dashboard/>}/>
          <Route exact path="/thankyou" element={<ThankYouPage />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </body>
    </Router>
  );
}

export default App;
