import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import image1 from '../../assets/images/Image-1.svg';
import logo from '../../assets/images/logo.svg'
import AuthSidebar from './Sidebar';
import loader1 from '../../assets/images/03-42-11-849_512.gif'
import axios from 'axios'

const LoginForm = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState({})
    const [isPending, setIsPending] = useState(false)
    const [serverError, setServerError] = useState(null);
    const [hideTop, setHideTop] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const navigate = useNavigate()
    useEffect(() => {
        const handleResize = () =>{
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize);
  
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const validateField = (field, value) => {
        let error = '';
        if(value !== undefined){
            if (field === 'email') {
                // Basic email validation (adjust as needed)
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) {
                    error = 'Invalid email address';
                }
            }
        }
        setError((prevErrors) => ({ ...prevErrors, [field]: error }));
    };

    const handleLogin = async (e) => {
        e.preventDefault()
        setIsPending(true);
        try {
          const hasErrors = Object.values(error).some((error) => error !== '');
          if (hasErrors) {
            setServerError('Login failed. Please try again....');
            setIsPending(false);
            return;
          }
          let data = { email, password }
          const response = await axios.post('https://www.swaptime.co/swaptime_server/user/login', JSON.stringify(data), {
            headers: { 'Content-Type': 'application/json' }});
            
          if (response.status === 200) {
            const token = response.data.token; // Assuming token is returned from backend
            localStorage.setItem('token', token);
            setServerError(response.data.message);
            setIsPending(false);
            console.log(response)
            navigate('/dashboard');
          } else {
            setServerError(response.data.message); // Use response.data.message for more accurate error messages
            setIsPending(false);
            throw new Error(response.data.message)
          }
        } catch (error) {
            console.log('Login failed:', error.response.data.message);
            //alert(error.response.data.message)
            setServerError(error.response.data.message);
            setIsPending(false);
        }
      };
    
    
    return (
        <section className="regForm bg-warning h-100 w-full" style={{height:"100%"}}>
            <div className='row bg-warning pb-5'>
                <AuthSidebar screenSize={screenWidth}/>
                <div className='col-md-6 pb-5'>
                    <center>
                        {isPending && <div className='h-100 text-xxl mt-10' ><img src={loader1} alt='loader' /><br/><p className='fs-2' style={{color:"black"}}>Logging you in...</p></div>}
                        {!isPending &&
                            <div style={{width:'70%'}}>
                                {
                                    !hideTop &&
                                    <center>
                                    <Link to="/"><h3 className='w-25 mt-5'><img src={logo} alt='swaptime logo'/></h3></Link>
                                        <p className='my-5 text-xl font-mono' style={{color:"black"}}>
                                            Swaptime offers a seamless way to get a service you need by offering a skill you have in exchange
                                        </p>
                                    </center>
                                }

                                <form className='mt-1'>
                                {serverError && <div className=" alert alert-danger px-4 py-2 rounded-md">{serverError}</div>}
                                    <div className='mb-2'>
                                        <input 
                                            type="email" 
                                            className="rounded-lg px-6 py-3 w-full" 
                                            id="email" 
                                            placeholder="Email Address"
                                            onChange={(e) =>{ 
                                                setEmail(e.target.value)
                                                validateField('email', e.target.value);
                                            }}
                                            onBlur={() => validateField('email', email)}
                                        />
                                        {error.email && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.email}</p>}
                                    </div>
                                    <div className='mb-4'>
                                        <input 
                                            type="password" 
                                            className="rounded-lg px-6 py-3 w-full" 
                                            id="password" 
                                            placeholder='Password'
                                            onChange={(e)=>{setPassword(e.target.value)}}
                                        />
                                    </div>
                                    <div className='mb-2 d-grid'>
                                        <button onClick={handleLogin} type="submit" style={{color:"#fff"}} className='btn btn-block btn-secondary bg-dark hover:bg-primary py-2'>LOG IN</button>
                                    </div>
                                </form>
                                {/*<center className='text-gray-600 my-6'>
                                    -------- OR -------

                                    <div className='mt-4 mb-2'>
                                        <button  type="submit" className='border-2 border-solid border-green-500 hover:bg-green-900 hover:text-white text-gray-700'>SIGN IN WITH GOOGLE</button>
                                    </div>
                                    <div className='mb-2'>
                                        <button type="submit" className='border-2 border-solid border-red-800 hover:bg-red-900 hover:text-white text-gray-700'>SIGN IN MICROSOFT</button>
                                    </div>
                                </center>*/}
                                        
                                <div className='mb-5' style={{color:"black"}}>
                                    Don't yet have an account? <Link to="/register" className='text-primary'>Create an Account</Link>
                                </div>
                            </div>
                        }
                    </center>
                </div>
            </div>
        </section>
    );
}

export default LoginForm;
