import React from 'react'
import Recommendation from './Recommendation'
import { Link } from 'react-router-dom'
import image1 from "../../assets/images/client-1.jpg";
import image2 from "../../assets/images/client-2.jpg";
import image3 from "../../assets/images/images-2.jpeg";
import defaultImage from '../../assets/images/userImage/defaultUserImage.png';
import DashboardCard from './utilityComponents/TopCards';

const MainView = ({renderView, userData}) =>{
    return(
        <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                        {/*<a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                                className="fas fa-download fa-sm text-white-50"></i> Generate Report</a>*/}
                </div>

                <div className="row">
                    <DashboardCard renderview={renderView} userData={userData} title="Create Swap" value="" icon="fa fa-plus-circle" color="primary" active={true} view="create_swap"/>
                    <DashboardCard renderview={renderView} userData={userData} title="View Swap Offers" value="4" icon="fa fa-line-chart" color="success"  active={true} view="view_offers"/>
                    <DashboardCard renderview={renderView} userData={userData} title="Active Swap Projects" value="0"  icon="fa fa-cubes"  color="info"/>
                    <DashboardCard renderview={renderView} userData={userData} title="Completed Swaps" value="0" icon="fa fa-handshake-o" color="warning"/>
                </div>  

                <div className='row'>
                    <div className="col-xl-7 col-lg-7">
                        <div className="card shadow mb-4">
                            <div
                                className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">Active Milestones</h6>
                                <div className="dropdown no-arrow">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                        aria-labelledby="dropdownMenuLink">
                                        <div className="dropdown-header">Dropdown Header:</div>
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="milestone_area">
                                    No Active Milestones yet...
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5">
                        <div class="card shadow mb-4">
                            <div
                                class="card-header pb-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Swap Offers</h6>
                                <div class="dropdown no-arrow">
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                        aria-labelledby="dropdownMenuLink">
                                        <div class="dropdown-header">Dropdown Header:</div>
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body overflow-auto">
                                <div class="chart-pie pt-4 pb-2">
                                <ViewOfferList name="John Gele" image={image1} rate="4.8" skill="Video Editing" renderView={renderView} />
                                <ViewOfferList name="Patricia Alice" image={image3} rate="4.9" skill="Video Editing" renderView={renderView} />
                                <ViewOfferList name="Adex Yohan" image={image2} rate="4.7" skill="Graphics Design" renderView={renderView} />
                                <ViewOfferList name="James" image={defaultImage} rate="4.5" skill="Graphics Design" renderView={renderView} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className='row'>
                    <div class="card shadow mb-4">
                        <div
                            class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Recommendations</h6>
                            <div class="dropdown no-arrow">
                                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                    aria-labelledby="dropdownMenuLink">
                                    <div class="dropdown-header">Dropdown Header:</div>
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="chart-pie pt-4 pb-2">
                                <Recommendation/>
                            </div>
                        </div>
                    </div>
                </div>*/}
                 
        </>
    )
}

export const ViewOfferList = ({name, image, skill, rate, renderView}) =>{
    return(
        <>
            <div className='row bg-light border mb-1 rounded p-2 cursor-pointer' onClick={()=>{renderView('view_offers')}}>
                <div className='col-md-4'>
                    <img src={image} alt="User Image" className="img-fluid mb-3 rounded" />
                </div>
                <div className='col-md-8'>
                    <span><b>Name:</b> {name}</span><br/>
                    <span><b>Skill: </b>{skill}</span><br/>
                    <span><b>Rating: </b>{rate}</span>
                </div>
            </div>
        </>
    )
}

export default MainView