import React from 'react'
import logo from '../../assets/images/logo.svg'
import { Link, useNavigate } from 'react-router-dom';
import LogoutButton from '../auth/Logout';

export const Sidebar = ({renderView,  userData}) => {
    
    return ( 
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            {/*<!-- Sidebar - Brand -->*/}
            <br/>
            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/dashboard">
                <div className="sidebar-brand-icon">
                    <img src={logo} alt='logo' className='w-50 mt-2'/>
                </div>
                {/*<div className="sidebar-brand-text mx-3">Swaptime</div>*/}
            </Link>

            {/*<!-- Nav Item - Dashboard -->*/}
            <li className="nav-item">
                <a className="nav-link" href="#" onClick={()=>renderView('home')}>
                    <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>Home</span></a>
            </li>

            {/*<!-- Divider -->*/}
            <hr className="sidebar-divider mb-2"/>
            <div className="sidebar-heading">
                Work Section
            </div>
            {/*<li className="nav-item">
                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i className="fas fa-fw fa-exchange-alt"></i>
                    <span>Swaps</span>
                </a>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Swaps</h6>
                        <a className="collapse-item" href="#" onClick={()=>renderView('puc')}>Create Swap Profile</a>
                        <a className="collapse-item" href="#" onClick={()=>renderView('puc')}>View Your Swaps</a>
                    </div>
                </div>
            </li>*/}
            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseOffers"
                    aria-expanded="true" aria-controls="collapseOffers">
                    <i className="fas fa-fw fa-tags"></i>
                    <span>Manage Swap Offers</span>
                </a>
                <div id="collapseOffers" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Offers</h6>
                        <a className="collapse-item" href="#" onClick={()=>renderView('create_swap')}>Create Offers</a>
                        <a className="collapse-item" href="#" onClick={()=>renderView('view_offers')}>View Offers</a>
                    </div>
                </div>
            </li>
            {/*<li className="nav-item">
                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseRequests"
                    aria-expanded="true" aria-controls="collapseRequests">
                    <i className="fas fa-fw fa-envelope"></i>
                    <span>Swap Request</span>
                </a>
                <div id="collapseRequests" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Offers</h6>
                        <a className="collapse-item" href="#" onClick={()=>renderView('puc')}>View Requests</a>
                    </div>
                </div>
            </li>*/}
            <li className="nav-item">
                <a className="nav-link" href="#" onClick={()=>renderView('puc')}>
                    <i className="fas fa-fw fa-flag-checkered"></i>
                    <span>Active Milestones</span></a>
            </li>
            {/*<hr className="sidebar-divider mb-2"/>
            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseReports"
                    aria-expanded="true" aria-controls="collapseReports">
                    <i className="fas fa-fw fa-chart-line"></i>
                    <span>Reports</span>
                </a>
                <div id="collapseReports" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Generate Reports</h6>
                        <a className="collapse-item" href="#" onClick={()=>renderView('puc')}>View Reports</a>
                    </div>
                </div>
            </li>*/}
            <hr className="sidebar-divider mb-2"/>
            <div className="sidebar-heading">
                Profile
            </div>
            <li className="nav-item">
                <a className="nav-link" href="#" onClick={()=>renderView('onboard')}>
                    <i className="fas fa-fw fa-user-edit"></i>
                    <span>Edit Profile</span></a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#" onClick={()=>renderView('puc')}>
                    <i className="fas fa-fw fa-lock"></i>
                    <span>Password Reset</span></a>
            </li>
            <div className="sidebar-heading">
                Help/Support
            </div>
            <li className="nav-item">
                <a className="nav-link" href="#" onClick={()=>renderView('puc')}>
                    <i className="fas fa-fw fa-address-book"></i>
                    <span>Help</span></a>
            </li>
            <li className="nav-item ">
                <a className="nav-link" href="#" onClick={()=>renderView('puc')}>
                    <i className="fas fa-fw fa-user"></i>
                    <span>Contact Us</span></a>
            </li>
            <LogoutButton/>


            {/*<!-- Divider -->*
            <hr className="sidebar-divider"/>

            {/*<!-- Heading -->
            <div className="sidebar-heading">
                Interface
            </div>

            {/*<!-- Nav Item - Pages Collapse Menu -->*
            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Components</span>
                </a>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Custom Components:</h6>
                        <a className="collapse-item" href="#" onClick={()=>renderView('puc')}>Buttons</a>
                        <a className="collapse-item" href="cards.html">Cards</a>
                    </div>
                </div>
            </li>

            {/*<!-- Nav Item - Utilities Collapse Menu -->*
            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseUtilities"
                    aria-expanded="true" aria-controls="collapseUtilities">
                    <i className="fas fa-fw fa-wrench"></i>
                    <span>Utilities</span>
                </a>
                <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Custom Utilities:</h6>
                        <a className="collapse-item" href="utilities-color.html">Colors</a>
                        <a className="collapse-item" href="utilities-border.html">Borders</a>
                        <a className="collapse-item" href="utilities-animation.html">Animations</a>
                        <a className="collapse-item" href="utilities-other.html">Other</a>
                    </div>
                </div>
            </li>

            {/*<!-- Divider -->*
            <hr className="sidebar-divider"/>

            {/*<!-- Heading -->*
            <div className="sidebar-heading">
                Addons
            </div>

            {/*<!-- Nav Item - Pages Collapse Menu -->*
            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages"
                    aria-expanded="true" aria-controls="collapsePages">
                    <i className="fas fa-fw fa-folder"></i>
                    <span>Pages</span>
                </a>
                <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Login Screens:</h6>
                        <a className="collapse-item" href="login.html">Login</a>
                        <a className="collapse-item" href="register.html">Register</a>
                        <a className="collapse-item" href="forgot-password.html">Forgot Password</a>
                        <div className="collapse-divider"></div>
                        <h6 className="collapse-header">Other Pages:</h6>
                        <a className="collapse-item" href="404.html">404 Page</a>
                        <a className="collapse-item" href="blank.html">Blank Page</a>
                    </div>
                </div>
            </li>

            {/*<!-- Nav Item - Charts -->*
            <li className="nav-item">
                <a className="nav-link" href="charts.html">
                    <i className="fas fa-fw fa-chart-area"></i>
                    <span>Charts</span></a>
            </li>

            {/*<!-- Nav Item - Tables -->*
            <li className="nav-item">
                <a className="nav-link" href="tables.html">
                    <i className="fas fa-fw fa-table"></i>
                    <span>Tables</span></a>
            </li>

            {/*<!-- Divider -->*
            <hr className="sidebar-divider d-none d-md-block"/>

            {/*<!-- Sidebar Toggler (Sidebar) -->
            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle"></button>
            </div>*/}


        </ul>
     );
}
 
export default Sidebar;